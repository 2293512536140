import { HttpClient } from '@/services/http';

export class DashboardService extends HttpClient {
  constructor(isLoading = true) {
    super(isLoading);
  }

  async getAvailableContracts() {
    const result = await this.get('v1/dashboard/contract/Available');
    return result;
  }

  async getContractCardData(contractId) {
    const result = await this.get(`v1/dashboard/contract/${contractId}`);
    return result;
  }

  async getInvoiceCardData(contractId) {
    const result = await this.get(
      `v1/dashboard/contract/${contractId}/invoices`
    );
    return result;
  }

  async getSupportCardData() {
    const result = await this.get(`v1/dashboard/support`);
    return result;
  }

  async getMovementCardData(contractId) {
    const result = await this.get(
      `v1/dashboard/contract/${contractId}/movements`
    );
    return result;
  }

  async getHealthManagementUrl() {
    const result = await this.get(`v1/dashboard/health-management`);
    return result;
  }
}
