<template>
  <v-container>
    <v-row v-if="contracts.length > 0">
      <v-col cols="12" sm="8" md="8" lg="8" xl="8">
        <div class="dash-info">
          <div class="dash-info--subtitle">Cliente empresa</div>
          <div class="dash-info--title">
            {{ customerName }}
          </div>
        </div>
      </v-col>
      <v-col cols="12" sm="4" md="4" lg="4" xl="4" class="d-flex align-center">
        <v-autocomplete
          item-text="value"
          item-value="id"
          solo
          flat
          hide-details
          :items="contracts"
          @change="setContract"
          v-model="contractId"
        >
          <template v-slot:selection="{ item }">
            <span class="caption">{{ createContractName(item.value) }}</span>
          </template>
          <template v-slot:item="{ item }">
            <span class="caption">
              {{ createContractName(item.value) }}
              <br />
              <small>{{ item.customerName }}</small>
            </span>
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>

    <v-row v-if="contractId">
      <v-col cols="12" sm="12" md="8" lg="8" xl="8">
        <CardBanner :isLoading="isLoading" />
      </v-col>
      <v-col cols="12" sm="12" md="4" lg="4" xl="4">
        <CardQuickAccess
          :contractId="contractId"
          :customerName="customerName"
          :customerId="customerId"
          :hasHealthManagement="hasHealthManagement"
          :isLoading="isLoading"
        />
      </v-col>
    </v-row>

    <v-row v-if="contractId">
      <v-col cols="12">
        <CardSupport :contractId="contractId" />
      </v-col>
    </v-row>

    <v-row v-if="contractId">
      <v-col cols="12" sm="12" md="7" lg="7" xl="7">
        <CardMovement :contractId="contractId" />
      </v-col>
      <v-col cols="12" sm="12" md="5" lg="5" xl="5">
        <CardContract :contractId="contractId" />
      </v-col>
    </v-row>

    <v-row v-if="contractId">
      <v-col cols="12">
        <CardInvoice :contractId="contractId" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { DashboardService } from '@/services/api/dashboard';

export default {
  components: {
    CardBanner: () => import('@/components/dashboard/card-banner'),
    CardQuickAccess: () => import('@/components/dashboard/card-quick-access'),
    CardMovement: () => import('@/components/dashboard/card-movement'),
    CardInvoice: () => import('@/components/dashboard/card-invoice'),
    CardSupport: () => import('@/components/dashboard/card-support'),
    CardContract: () => import('@/components/dashboard/card-contract')
  },

  data: () => ({
    isLoading: false,
    contractId: null,
    customerId: null,
    customerName: null,
    contracts: [],
    hasHealthManagement: false
  }),

  created() {
    this.search();
  },

  methods: {
    async search() {
      try {
        this.isLoading = true;
        const dashboardService = new DashboardService(false);
        const { status, data } = await dashboardService.getAvailableContracts();

        if (status === 200) {
          data.forEach(contract => {
            contract.value = `${contract.description} - ${contract.customerName}`;
          });
          const orderedConctracts = data.length
            ? data.sort((a, b) => (a.customerName > b.customerName ? 1 : -1))
            : data;

          this.contracts = orderedConctracts;
          this.setContract();
        }
      } catch (error) {
        throw new Error(error);
      } finally {
        this.isLoading = false;
      }
    },

    setContract(id) {
      let contract = null;

      if (id) {
        contract = this.contracts.find(contract => contract.id === id);
      } else {
        contract = this.contracts.length > 0 ? this.contracts[0] : null;
      }

      if (contract) {
        this.contractId = contract.id;
        this.customerId = contract.customerId;
        this.customerName = contract.customerName;
        this.hasHealthManagement = contract.hasHealthManagement;
      }
    },

    createContractName(value) {
      const names = value.split('-');
      return `${names[0]}-${names[1]}`.trim();
    }
  },

  watch: {
    contractId() {
      // criar efeito skeleton nos componentes estaticos
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false;
      }, 500);
    }
  }
};
</script>

<style lang="scss">
.dash-info {
  margin: 1rem 0;

  &--title {
    font-size: 16px;
    font-weight: 400;
    text-transform: uppercase;
  }

  &--subtitle {
    font-size: 14px;
    font-weight: 300;
  }
}
</style>
